import {createApp} from "vue";
import App from "./App.vue";
import axios from "axios";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import BootstrapVue3 from "bootstrap-vue-3";
import VSelect from "vue-select";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import "vue-select/dist/vue-select.css";
import "@vueform/multiselect/themes/default.css";
import authHeader from "./core/services/auth-header";

const app = createApp(App);
app.component("v-select", VSelect);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(BootstrapVue3);
initInlineSvg(app);

app.use(i18n);
app.mount("#app");
axios.defaults.headers = authHeader();

if (process.env.VUE_APP_ENV == "local") {
   axios.defaults.baseURL = "http://127.0.0.1:8000/api/forum-tool";
} else if (process.env.VUE_APP_ENV == "development") {
   axios.defaults.baseURL = "https://dev2.mymedisage.com/api/forum-tool";
} else if (process.env.VUE_APP_ENV == "staging") {
   axios.defaults.baseURL = "https://stagingadmin-api.mymedisage.com/api/forum-tool";
} else if (process.env.VUE_APP_ENV == "production") {
   axios.defaults.baseURL = "https://mymedisage.com/api/forum-tool";
} else {
   axios.defaults.baseURL = "http://127.0.0.1:8000/api/forum-tool";
}

axios.interceptors.request.use((config) => {
   let token: any = "";
   if (localStorage.getItem("token") != "" && localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
   }
   config.headers["Authorization"] = `Bearer ${token}`;
   return config;
});

app.mixin({
   methods: {
      can: (key) => {
         if (localStorage.getItem('permissions') != null && localStorage.getItem("permissions") != "") {
            const permissions: any = localStorage.getItem("permissions");
            if (JSON.parse(permissions).includes(key)) {
               return true;
            } else {
               return false;
            }
         }
      }
   }
});
