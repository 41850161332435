// Login & Verify
const login = "/login";
const verify_token = "/verify-token";

//Video
const video = {
  getVideo: "/get-videos",
  publishVideo: "/publish-video",
  getMostViewedVideo: "/get-most-viewed-videos?filter=all",
  getSingleVideo: "/get-video",
  updateVideo: "/update-video",
}

//Case
const cases = {
  getCase: "/get-cases",
  getMostViewedCase: "/get-most-viewed-cases?filter=all",
  publishCase: "/publish-case",
  getSingleCase: "/get-case",
  updateCase: "/update-case",
}

//Reading Material
const readingMaterial = {
  getReadingMaterial: "/get-reading-materials",
  publishReadingMaterial: "/publish-reading-material",
  getSingleReadingMaterial: "/get-reading-material",
  updateReadingMaterial: "/update-reading-material",
}

//Live Event
const liveEvent = {
  getLiveEvent: "/get-live-events",
  getMostViewedLiveEvent: "/get-most-members-events?filter=all",
  publishLiveEvent: "/publish-live-event",
  getSingleLiveEvent: "/get-live-event",
  updateLiveEvent: "/update-live-event",
}

//Audience
const audience = {
  getAudience: "/audience-filters",
  getAudienceData: "/get-data",
}

const forumToolData = "forum-tool-data";

// Campaigns
const campaigns = {
  storeCampaigns: "/campaigns",
  getSingleCampaign: "/campaigns",
  getLatestCampaign: "/campaigns/get-last-campaign",
};

const touchpoint = {
  getTouchpoint: "/fetch-touchpoints",
  storeTouchpoint: "/touchpoints",
}

// Templates
const templates = {
  getTemplates: "/get-forum-templates",
}

//stages 
const stages = {
  stageUrl: "/forum-stages",
  approveContent: "/approve-content-stage",
}

const analytics = {
  getAnalytics: "/analytics",
}

const brandFile = {
  getHcp: "/brand-files",
  getBrandCount: "/brand-hcp-count",
  getBrandDoctors: "/brand-doctors",
  getHcpList: '/brand-doctors',
  getFieldList: '/brand-reps',
  getTagList: "/get-tag-list-by-brand",
  getBrandCredits: '/brand-credits',
  getTransactionHistory: '/transaction-history'
}

const detailOne = {
  getDetailOne: "/detail-one",
  restoreDetail: "/detail-one/restore"
}

export {
  login,
  verify_token,
  video,
  cases,
  readingMaterial,
  liveEvent,
  audience,
  forumToolData,
  campaigns,
  touchpoint,
  templates,
  stages,
  analytics,
  brandFile,
  detailOne
};
