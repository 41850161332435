import {createRouter, createWebHashHistory} from "vue-router";
import {verify_token} from "../ts/path";
import axios from "axios";
import authHeader from "../core/services/auth-header";

const routes = [
   {
      path: "/:pathMatch(.*)*",
      component: () => import ("@/views/crafted/authentication/basic-flow/404.vue"),
      name: "NotFound",
      meta: {
         pageTitle: "Not Found"
      }
   }, {
      path: "/",
      redirect: "/sign-in",
      component: () => import ("@/layouts/AuthLayout.vue"),
      children: [
         {
            path: "/sign-in",
            name: "sign-in",
            component: () => import ("@/views/crafted/authentication/basic-flow/SignIn.vue"),
            meta: {
               pageTitle: "Sign In"
            }
         },
      ]
   }, 
   {
      path: "/",
      redirect: "sign-in",
      component: () => import("@/layouts/main-layout/ReviewLayout.vue"),
      children: [
         {
            path: "/hcp/hcp-csv/:brand_doctor_id/:file_name",
            name: "single-hcp-csv",
            component: () => import ("@/views/partner/NewDesign/HCP/SingleHcpCsv.vue"),
            meta: {
               authRequired: true,
               pageTitle: "HCP"
            }
         },
         {
            path: "/hcp/hcp-csv",
            name: "hcp-csv",
            component: () => import ("@/views/partner/NewDesign/HCP/HcpCsv.vue"),
            meta: {
               authRequired: true,
               pageTitle: "HCP"
            }
         },
         {
            path: "/hcp/hcp-list",
            name: "hcp-list",
            component: () => import ("@/views/partner/NewDesign/HCP/HcpList.vue"),
            meta: {
               authRequired: true,
               pageTitle: "HCP"
            }
         },
         {
            path: "/review-video/:id",
            name: "review-video",
            component: () => import ("@/views/partner/Common/VideoReview.vue"),
            meta: {
               pageTitle: "Review"
            }
         },
         {
            path: "/review-case/:id",
            name: "review-case",
            component: () => import ("@/views/partner/Common/CaseReview.vue"),
            meta: {
               pageTitle: "Review"
            }
         },
         {
            path: "/review-reading-material/:id",
            name: "review-reading-material",
            component: () => import ("@/views/partner/Common/ReadingMaterialReview.vue"),
            meta: {
               pageTitle: "Review"
            }
         }, {
            path: "/review-live-event/:id",
            name: "review-live-event",
            component: () => import ("@/views/partner/Common/LiveEventReview.vue"),
            meta: {
               pageTitle: "Review"
            }
         },
      ],
   },
   {
      path: "/dashboard",
      component: () => import ("@/layouts/main-layout/MainLayout.vue"),
      children: [
         {
            path: "/404",
            name: "404",
            component: () => import ("@/views/crafted/authentication/basic-flow/404.vue"),
            meta: {
               authRequired: true,
               pageTitle: "404"
            }
         },
         {
            path: "/view-transactions",
            name: "view-transactions",
            component: () => import ("@/views/partner/Transaction/Transactions.vue"),
            meta: {
               authRequired: true,
               pageTitle: "Transaction",
               permission: "transaction"
            },
         },
         {
            path: "/my-content/:action_type",
            name: "my-content",
            component: () => import ("@/views/partner/NewDesign/MyContent.vue"),
            meta: {
               authRequired: true,
               pageTitle: "My Content",
               permission: "my-content"
            }
         }, {
            path: "/content-to-publish/:action_type",
            name: "content-to-publish",
            component: () => import ("@/views/partner/NewDesign/ContentToPublish.vue"),
            meta: {
               pageTitle: "Content to Publish",
               permission: "content-to-publish"
            }
         }, {
            path: "/campaign",
            name: "campaign",
            component: () => import ("@/views/partner/NewDesign/campaign.vue"),
            meta: {
               pageTitle: "Campaign"
            }
         }, {
            path: "/view-campaign/:id",
            name: "view-campaign",
            component: () => import ("@/views/partner/NewDesign/ViewCampaign.vue"),
            meta: {
               pageTitle: "Campaign"
            }
         }, {
            path: "/campaign/:id/create-touchpoint",
            name: "create-touchpoint",
            component: () => import ("@/views/partner/Campaign/CampaignNew.vue"),
            meta: {
               authRequired: true,
               pageTitle: "Touchpoint"
            }
         }, {
            path: "/campaign/:id/market-touchpoint",
            name: "market-touchpoint",
            component: () => import ("@/views/partner/Campaign/CampaignNew.vue"),
            meta: {
               authRequired: true,
               pageTitle: "Campaign"
            }
         },  {
            path: "/campaign/:id/copy-touchpoint",
            name: "copy-touchpoint",
            component: () => import ("@/views/partner/Campaign/CampaignNew.vue"),
            meta: {
               authRequired: true,
               pageTitle: "Campaign"
            }
         }, {
            path: "/campaign/:id/update-touchpoint",
            name: "update-touchpoint",
            component: () => import ("@/views/partner/Campaign/CampaignNew.vue"),
            meta: {
               authRequired: true,
               pageTitle: "Touchpoint"
            }
         }, {
            path: "/hcp",
            name: "hcp",
            component: () => import ("@/views/partner/NewDesign/HCP/Hcp.vue"),
            meta: {
               authRequired: true,
               pageTitle: "HCP"
            }
         }, {
            path: "/hcp/create-hcp",
            name: "create-hcp",
            component: () => import ("@/views/partner/NewDesign/HCP/CreateHcp.vue"),
            meta: {
               pageTitle: "HCP"
            }
         }, {
            path: "/hcp/edit-hcp/:id",
            name: "edit-hcp",
            component: () => import ("@/views/partner/NewDesign/HCP/CreateHcp.vue"),
            meta: {
               pageTitle: "HCP"
            }
         }, {
            path: "/field-mr",
            name: "field-mr",
            component: () => import ("@/views/partner/NewDesign/FieldMR/FieldMR.vue"),
            meta: {
               authRequired: true,
               pageTitle: "Field MR"
            }
         }, {
            path: "/field-mr/create-field-mr",
            name: "create-field-mr",
            component: () => import ("@/views/partner/NewDesign/FieldMR/CreateFieldMR.vue"),
            meta: {
               pageTitle: "Field MR"
            }
         }, {
            path: "/field-mr/edit-field-mr/:id",
            name: "edit-field-mr",
            component: () => import ("@/views/partner/NewDesign/FieldMR/CreateFieldMR.vue"),
            meta: {
               pageTitle: "Field MR"
            }
         }, {
            path: "/field-mr/field-mr-list",
            name: "field-mr-list",
            component: () => import ("@/views/partner/NewDesign/FieldMR/FieldMRList.vue"),
            meta: {
               authRequired: false,
               pageTitle: "Field MR"
            }
         }, {
            path: "/field-mr/uploaded-field-mr",
            name: "field-mr-csv",
            component: () => import ("@/views/partner/NewDesign/FieldMR/FieldMRCsv.vue"),
            meta: {
               authRequired: false,
               pageTitle: "Field MR"
            }
         }, {
            path: "/touchpoint-analytics/:id",
            name: "view-touchpoint-analytics",
            component: () => import ("@/views/partner/NewDesign/touchpointAnalytics.vue"),
            meta: {
               authRequired: false,
               pageTitle: "Touchpoint Analytics"
            }
         },
         {
            path: "/detailOne",
            name: "detailOne",
            component: () => import ("@/views/partner/DetailOne/index.vue"),
            meta: {
               authRequired: false,
               pageTitle: "DetailOne",
               permission: "detail-one"
            }
         },
         {
            path: "/detailOne/addDetailOne",
            name: "add-detailOne",
            component: () => import ("@/views/partner/DetailOne/addDetailOne.vue"),
            meta: {
               authRequired: false,
               pageTitle: "DetailOne",
               permission: "add-detail1"
            }
         },
         {
            path: "/detailOne/editDetailOne/:id",
            name: "edit-detailOne",
            component: () => import ("@/views/partner/DetailOne/addDetailOne.vue"),
            meta: {
               authRequired: false,
               pageTitle: "DetailOne",
               permission: "edit-detail1"
            }
         }
      ]
   },
];

const router = createRouter({history: createWebHashHistory(), routes});

router.beforeEach(async (to, from, next) => {
   const pathTo = ["/sign-in"].includes(to.path);
   if (to.meta.authRequired == true) {
      if (localStorage.getItem("token")) {
         try {
            axios.defaults.headers = authHeader();
            const verify = await axios.get(verify_token);
            const permissions = verify.data.response.permissions;
            if (permissions) {
               if (to.path.includes('my-content')) {
                  if (permissions.filter(p => p == 'my-content').length == 0) {
                     router.push({name: "404"});
                  }
               }
            }
            next();
         } catch (error) {
            localStorage.removeItem("token");
            next({name: "sign-in"});
         }
      } else {
         next({name: "sign-in"});
      }
   } else {
      if (! pathTo && !localStorage.getItem("token")) {
         next({name: "sign-in"});
      }
      if (pathTo && localStorage.getItem("token")) {
         localStorage.removeItem("token");
         window.location.reload();
         next({
            name: "my-content",
            params: {
               action_type: 'video'
            }
         });
      }
      next();
   }
   // current page view title
   document.title = `${
      to.meta.pageTitle
   } - ${
      process.env.VUE_APP_NAME
   }`;
   // Scroll page to top on every route change
   window.scrollTo({top: 0, left: 0, behavior: "smooth"});
});

export default router;
